<h2 mat-dialog-title>{{ data?.title }}</h2>

<div mat-dialog-content>
    <!--Multi select drop down  -->
    <div *ngIf="isMultiSelect">
        <app-tags-autocomplete 
        [tags]="allAvailableTags" 
        [config]="config"
        (change)="onSelectedTagsChanged($event)">
      </app-tags-autocomplete>
      </div>

    <!-- First Dropdown -->
    <mat-form-field class="full-width" appearance="outline" *ngIf="!isMultiSelect">
        <mat-select [placeholder]="firstPlaceholder" (selectionChange)="onFirstSelection($event.value)">
            <mat-option *ngFor="let option of firstOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Second Dropdown (Shown based on first selection) -->
    <mat-form-field *ngIf="showSecondOptions" class="full-width" appearance="outline">
        <mat-select placeholder="Choose a sub-option" (selectionChange)="onSecondSelection($event.value)">
            <mat-option *ngFor="let option of secondOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
    </mat-form-field>

    <!-- SubTitle (Shown if applicable) -->
    <p *ngIf="currentSubTitle && showThirdOptions" class="sub-title mm-mb-0">{{ currentSubTitle }}</p>

    <!-- Third Dropdown (Shown based on second selection) -->
    <mat-form-field *ngIf="showThirdOptions" class="full-width" appearance="outline">
        <mat-select placeholder="Select Yes or No" (selectionChange)="onThirdSelection($event.value)">
            <mat-option *ngFor="let option of thirdOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Free Text Area (Shown when needed) -->
    <mat-form-field *ngIf="showTextArea" class="full-width" appearance="outline">
        <textarea matInput [placeholder]="textAreaPlaceholder" [(ngModel)]="otherText"></textarea>
    </mat-form-field>
</div>

<div mat-dialog-actions align="end">
    <button mat-flat-button class="mm-bg-pink mm-text-white" (click)="dialogRef.close()">Cancel</button>
    <button mat-flat-button class="mm-bg-primary mm-text-white" [disabled]="!canSubmit()" (click)="closeDialog()">Submit</button>
</div>
