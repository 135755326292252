import { Component, Input, OnInit } from '@angular/core';
import { capitalizeEachWord } from 'app/shared/helpers/utils';
import { ShiftMetrics } from 'app/shared/models/shift.model';
import { ManageShiftsService } from 'app/shared/services/manage-shifts/manage-shifts.service';
export interface MetricsMap {
  key: string;
  value: ShiftMetrics[keyof ShiftMetrics]
}
@Component({
  selector: 'app-floating-shift-metrics',
  templateUrl: './floating-shift-metrics.component.html',
  styleUrls: ['./floating-shift-metrics.component.scss']
})
export class FloatingShiftMetricsComponent implements OnInit {
  showMetrics: boolean = false;
  isLoading: boolean = false;
  metricsMapping: MetricsMap[] = [];
  @Input() shouldShowFloatingButton: boolean = false;
  readonly allowedMetricKeys: (keyof ShiftMetrics)[] = [
    'orders_completed',
    'orders_escalted',
    'average_consult_time',
    'rate',
    'consults_per_hour',
    'idle_time',
    'time_elapsed'
  ];

  constructor(private manageShiftsService: ManageShiftsService) { }

  ngOnInit(): void { }

  mapMetrics(metrics: ShiftMetrics): void {
    if (metrics) {
      this.metricsMapping = Object.entries(metrics)
        .filter(([key]) => this.allowedMetricKeys.includes(key as keyof ShiftMetrics))
        .map(([key, value]) => ({
          key: capitalizeEachWord(key.replace(/_/g, ' ')),
          value: value as ShiftMetrics[keyof ShiftMetrics],
        }));
    }
  }

  toggleMetrics() {
    if (!this.showMetrics) {
      this.fetchMetrics(true);
    } else {
      this.showMetrics = false;
    }
  }

  fetchMetrics(shouldShowMetrics: boolean = false) {
    this.isLoading = true;
    this.manageShiftsService.getClinicianShiftStatus().subscribe(
      (res) => {
        this.mapMetrics(res.metrics);
        if (this.metricsMapping.length) {
          this.showMetrics = shouldShowMetrics;
        }
      },
      (err) => {
        this.isLoading = false;
        console.error('Error fetching metrics:', err);
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
