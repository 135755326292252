import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, } from 'rxjs';
import { OnShiftClinician, Session, Shift, ShiftStatusResponse, ShiftsResponse } from '../../models/shift.model';
import { config } from 'config';
import { HttpClient } from '@angular/common/http';
import { ShiftAssignStatusEnum } from 'app/shared/helpers/status-options.helper';
import { tap } from 'rxjs/operators';
import { createHttpParams } from 'app/shared/helpers/utils';

export interface GetShiftRequest {
  start_date: string;
  end_date: string;
}

@Injectable({
  providedIn: 'root',
})
export class ManageShiftsService {
  constructor(private http: HttpClient) { }

  // BehaviorSubject to hold and emit the latest shift status
  private shiftDetailsSubject = new BehaviorSubject<ShiftStatusResponse | null>(null);
  shiftDetails$ = this.shiftDetailsSubject.asObservable();

  getShifts(request: GetShiftRequest) {
    const params = createHttpParams(request);
    const url = config.apiUrl1 + 'clinician_shifts';
    return this.http.get<ShiftsResponse>(url, { params });
  }

  getOnShiftClinician(): Observable<OnShiftClinician[]> {
    const url = config.apiUrl1 + 'onShiftClinicians';
    return this.http.get<OnShiftClinician[]>(url)
  }

  getShift(id: number) {
    const url = `${config.apiUrl1}clinician_shift/${id}`;
    return this.http.get<any>(url);
  }

  findAvailableClinicians(session: Session): Observable<any> {
    const url = `${config.apiUrl1}clinician_shifts/get_available_doctors`;
    return this.http.post<any>(url, session);
  }

  createShift(shift: Shift) {
    const url = `${config.apiUrl1}clinician_shifts/store`;
    return this.http.post(url, shift);
  }

  updateShift(updatedShift: Shift): Observable<any> {
    const url = `${config.apiUrl1}clinician_shift/update/${updatedShift.shiftId}`;
    return this.http.post(url, updatedShift);
  }


  updateStatus(data: any): Observable<any> {
    const url = `${config.apiUrl1}clinician_shift/updateStatus`;
    return this.http.post<any>(url, data);
  }

  syncWithDeputy() {
    const url = `${config.apiUrl1}clinician_shift/sync`;
    return this.http.post<any>(url, {});
  }


  getClinicianShiftStatus(): Observable<ShiftStatusResponse> {
    const url = `${config.apiUrl1}clinician/get/shiftStatus`;
    return this.http.get<ShiftStatusResponse>(url).pipe(
      tap(data => this.shiftDetailsSubject.next(data)))
  }


  isActionDisabledForClinician(): boolean {
    const shiftStatus = this.shiftDetailsSubject.value?.shiftStatus;
    return [ShiftAssignStatusEnum.Paused, ShiftAssignStatusEnum.Assigned].includes(shiftStatus);
  }

}
