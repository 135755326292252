import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import {
  ADMIN_OPTIONS,
  BulkActionStatus,
  STATUSES_WITH_BULK_ACTIONS,
} from 'app/shared/helpers/status-options.helper';
import { DataOrderService } from 'app/shared/services/data-order.service';
import { Observable, Subscription } from 'rxjs';

interface ComponentData {
  fromStatus: Observable<BulkActionStatus>;
  orderCount: number;
}
@Component({
  selector: 'app-change-order-status-dialog',
  templateUrl: './change-order-status-dialog.component.html',
  styleUrls: ['./change-order-status-dialog.component.scss'],
})
export class ChangeOrderStatusDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  fromStatus: BulkActionStatus;
  subscription: Subscription = new Subscription();
  orderCount = 0;
  error = '';
  @ViewChild('selectionList') _selectionList: MatSelectionList;

  constructor(
    public dialogRef: MatDialogRef<ChangeOrderStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentData,
    public dataOrderService: DataOrderService
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.data.fromStatus.subscribe(status => (this.fromStatus = status))
    );
    this.orderCount = this.data.orderCount;
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this._selectionList.selectionChange.subscribe(() => (this.error = ''))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  statuses() {
    return ADMIN_OPTIONS[this.fromStatus].actions.filter((item: BulkActionStatus) =>
      STATUSES_WITH_BULK_ACTIONS.includes(item)
    );
  }

  getValue(selected: MatSelectionList) {
    return selected.selectedOptions.selected?.[0]?.value;
  }

  submit() {
    if (!this._selectionList.selectedOptions.hasValue()) {
      this.error = 'Please select a status from the list.';
      return;
    }

    const toStatus = this._selectionList._value[0]

    this.dialogRef.close(toStatus);
  }
}
