<mat-form-field class="tags-autocomplete" [appearance]="options.appearance"
    (click)="$event.stopPropagation();trigger.openPanel()" [class.mat-form-field-invalid]="isError">
    <mat-label>{{options.label}}</mat-label>
    <mat-chip-list #chipList aria-label=" Tags selection">
        <mat-chip *ngFor="let tag of selectedTags$ | async" [selectable]="options.selectable"
            [removable]="options.removable" [class.highlight]="tag.highlight" (removed)="remove(tag.id)">
            {{tag.name}}
            <mat-icon matChipRemove *ngIf="options.removable">cancel</mat-icon>
        </mat-chip>
        <input [placeholder]="getPlaceholder()" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)" #trigger="matAutocompleteTrigger" [ngClass]="{'error': isError}">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngIf="(filteredTags | async)?.length < 3 && tagCtrl.value && options.addTag"
            [value]="tagCtrl.value" class="custom-option">
            <span class="new-tag-prefix">{{options.addTagText+ ': '}}</span><span
                class="new-tag">{{tagCtrl.value}}</span>
        </mat-option>
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag.id">
            <div class="d-flex align-items-center">
                <mat-icon>{{ isTagSelected(tag.name) ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
                <span>{{ tag.name }}</span>
            </div>
        </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
</mat-form-field>
<mat-error *ngIf="isError" class="error-message">
    {{ errorMessage }}
</mat-error>