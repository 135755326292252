import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleService implements OnDestroy {
  private idleThreshold = 60;  // threshold in seconds
  private idleSubject = new Subject<void>();
  public idleState = this.idleSubject.asObservable();
  private visibilityChangeEvent = 'visibilitychange';  // Event to detect tab switches
  private hiddenProperty = 'hidden';  // Property to check tab visibility
  private lastEventTime = Date.now(); // Time of the last event trigger

  // Store references to event listeners to remove them later
  private mouseMoveListener: () => void;
  private keyDownListener: () => void;
  private visibilityChangeListener: () => void;
  private scrollListener: () => void;
  private touchStartListener: () => void;
  private clickListener: () => void;

  // Store the interval ID to clear the timer when needed
  private idleTimerIntervalId: any;

  constructor() {

  }

  init() {
    this.setupIdleTimer();
    this.startIdleTimer();
  }

  private setupIdleTimer() {
    // Listen to visibility changes to detect when the user switches tabs
    this.visibilityChangeListener = () => this.handleVisibilityChange();
    document.addEventListener(this.visibilityChangeEvent, this.visibilityChangeListener);

    // Listen to mouse movements, key presses, scroll, touch events, and clicks to reset the idle timer
    this.mouseMoveListener = () => this.resetIdleTimer();
    this.keyDownListener = () => this.resetIdleTimer();
    this.scrollListener = () => this.resetIdleTimer();
    this.touchStartListener = () => this.resetIdleTimer();
    this.clickListener = () => this.resetIdleTimer();

    document.addEventListener('mousemove', this.mouseMoveListener);
    document.addEventListener('keydown', this.keyDownListener);
    document.addEventListener('scroll', this.scrollListener);
    document.addEventListener('touchstart', this.touchStartListener);
    document.addEventListener('click', this.clickListener);
  }

  private resetIdleTimer() {
    // Reset idle time on any user interaction
    this.lastEventTime = Date.now();  // Update the last event time to now
  }

  private startIdleTimer() {
    // Start checking for idle state every second after the first user event
    this.idleTimerIntervalId = setInterval(() => this.checkIdleState(), 1000);
  }

  private checkIdleState() {
    const currentTime = Date.now();
    const elapsedTime = Math.floor((currentTime - this.lastEventTime) / 1000); // Calculate time difference in seconds

    if (elapsedTime >= this.idleThreshold) {
      this.idleSubject.next();  // Trigger idle event if threshold reached
      this.lastEventTime = currentTime;  // Update last event time
    }
  }

  private handleVisibilityChange() {
    if (document[this.hiddenProperty]) {
      // The tab is hidde
    } else {
      // The tab is visible again, continue counting idle time
      this.resetIdleTimer();
    }
  }

  // Cleanup event listeners and clear the interval timer when the service is destroyed
  ngOnDestroy() {
    document.removeEventListener(this.visibilityChangeEvent, this.visibilityChangeListener);
    document.removeEventListener('mousemove', this.mouseMoveListener);
    document.removeEventListener('keydown', this.keyDownListener);
    document.removeEventListener('scroll', this.scrollListener);
    document.removeEventListener('touchstart', this.touchStartListener);
    document.removeEventListener('click', this.clickListener);

    // Clear the interval timer to stop idle state checks
    if (this.idleTimerIntervalId) {
      clearInterval(this.idleTimerIntervalId);
    }
  }
}
