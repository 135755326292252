import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Observable, BehaviorSubject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';


export interface TagsAutocompleteOptions {
  placeholder: string;
  label: string;
  appearance: MatFormFieldAppearance;
  addTagText: string;
  addTag: boolean;
  selectable: boolean;
  removable: boolean;
}

export type TagsAutocompleteOptionsPartial = Partial<TagsAutocompleteOptions>;
export interface AutocompleteTag {
  id?: number | string;
  name: string;
  highlight?: boolean;
  custom?: boolean;
}

const DEFAULT_TAG_AUTOCOMPLETE_OPTIONS: TagsAutocompleteOptions = {
  placeholder: 'Document Type...',
  label: 'Document Type',
  appearance: 'outline',
  addTagText: 'Create New Type',
  addTag: true,
  selectable: false,
  removable: true
};

@Component({
  selector: 'app-tags-autocomplete',
  templateUrl: './tags-autocomplete.component.html',
  styleUrls: ['./tags-autocomplete.component.scss']
})
export class TagsAutocompleteComponent implements OnInit {
  @Input() config: TagsAutocompleteOptionsPartial = {};
  @Input() defaultTags: AutocompleteTag[] = [];
  @Input() tags: AutocompleteTag[] = [];
  @Output() change = new EventEmitter<AutocompleteTag[]>();
  @Input() isError: boolean = false;
  @Input() errorMessage: string = '';

  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl('');
  filteredTags: Observable<AutocompleteTag[]>;
  options: TagsAutocompleteOptions = { ...DEFAULT_TAG_AUTOCOMPLETE_OPTIONS, ...this.config };
  private selectedTagsSubject = new BehaviorSubject<AutocompleteTag[]>([]);
  selectedTags$ = this.selectedTagsSubject.asObservable();

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  constructor() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      debounceTime(300),
      startWith(''),
      map((inputText: string | null) => this._filterTags(inputText))
    );

    this.selectedTags$.subscribe(tags => this.change.emit(tags));
  }

  ngOnInit(): void {
    this.updateOptions();
    this.selectedTagsSubject.next(this.defaultTags);
  }

  ngAfterViewInit() {
    this.matAutocompleteTrigger.panelClosingActions.subscribe(event => {
      if (event) {
        setTimeout(() => this.matAutocompleteTrigger.openPanel());
      }
    });
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event): void {
    if (!this.tagInput.nativeElement.contains(event.target as Node)) {
      this.matAutocompleteTrigger.closePanel();
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const currentTags = this.selectedTagsSubject.getValue();
    if (value) {
      const existingTag = this.tags.find(tag => tag.name.toLowerCase() === value.toLowerCase());
      const isTagSelected = this.isTagSelected(value);
      if (isTagSelected) {
        // Highlight existing tag
        this.highlightTag(value);
      } else {
        const newSelectedTags = [...currentTags, existingTag ?? { id: value, name: value, custom: true }];
        this.selectedTagsSubject.next(newSelectedTags);
      }
    }
    event.chipInput!.clear();
    this.tagCtrl.setValue(null);
  }

  remove(tagId: AutocompleteTag['id']): void {
    const newSelectedTags = this.selectedTagsSubject.getValue().filter(tag => tag.id !== tagId);
    this.selectedTagsSubject.next(newSelectedTags);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tags'] && changes['tags'].currentValue) {
      this.updateOptions();
      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        debounceTime(300),
        startWith(''),
        map((inputText: string | null) => this._filterTags(inputText))
      );
    }

    if (changes['defaultTags'] && changes['defaultTags'].currentValue) {
      const defaultTags = changes['defaultTags'].currentValue;
      const selectedTags = this.tags.filter(tag => defaultTags.includes(tag.id));
      this.selectedTagsSubject.next(selectedTags);
    }
  }

  private updateOptions(): void {
    this.options = { ...DEFAULT_TAG_AUTOCOMPLETE_OPTIONS, ...this.config };
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const tagId = event.option.value as AutocompleteTag['id'];
    if (!this.isTagSelected(tagId)) {
      const selectedTag = this.tags.find(tag => tag.id === tagId);
      const newSelectedTags = [...this.selectedTagsSubject.getValue(), selectedTag ?? { id: tagId, name: tagId as string, custom: true }];
      this.selectedTagsSubject.next(newSelectedTags);
    } else {
      this.remove(tagId);
      // Highlight existing tag
      // this.highlightTag(tagId);
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filterTags(text: string | null): AutocompleteTag[] {
    const filterValue = text?.toLowerCase() || '';
    return this.tags.filter(tag => tag?.name.toLowerCase().includes(filterValue));
  }

  private isTagSelected(value: string | number): boolean {
    const lowerValue = typeof value === 'string' ? value.toLowerCase() : value;
    return this.selectedTagsSubject.getValue().some(tag =>
      (typeof tag.id === 'string' ? tag.id.toLowerCase() : tag.id) === lowerValue || tag.name.toLowerCase() === lowerValue
    );
  }

  getPlaceholder(): string {
    return this.selectedTagsSubject.getValue().length ? '' : this.options.placeholder;
  }

  private highlightTag(value: string | number): void {
    const lowerValue = typeof value === 'string' ? value.toLowerCase() : value;
    const currentTags = this.selectedTagsSubject.getValue().map(tag => {
      if ((typeof tag.id === 'string' ? tag.id.toLowerCase() : tag.id) === lowerValue || tag.name.toLowerCase() === lowerValue) {
        return { ...tag, highlight: true };
      }
      return tag;
    });
    this.selectedTagsSubject.next(currentTags);

    // Remove highlight after 3 seconds
    setTimeout(() => {
      const updatedTags = this.selectedTagsSubject.getValue().map(tag => {
        if ((typeof tag.id === 'string' ? tag.id.toLowerCase() : tag.id) === lowerValue || tag.name.toLowerCase() === lowerValue) {
          return { ...tag, highlight: false };
        }
        return tag;
      });
      this.selectedTagsSubject.next(updatedTags);
    }, 3000);
  }
}
