import { Injectable } from '@angular/core';
import { AlertService } from './alert/alert.service';

@Injectable({
    providedIn: 'root',
})
export class ClipboardService {
    constructor(private alertService: AlertService) { }

    copyToClipboard(value: string, fieldName?: string): void {
        navigator.clipboard.writeText(value).then(
            () => {
                const msg = fieldName
                    ? `${fieldName} copied to clipboard!`
                    : 'Copied to clipboard!';
                this.alertService.notifySuccess(msg);
            },
            (err) => this.alertService.notifyError('Failed to copy to clipboard.', err)
        );
    }
}
