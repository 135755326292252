import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingShiftMetricsComponent } from './floating-shift-metrics.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [FloatingShiftMetricsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatButtonModule
  ],
  exports: [FloatingShiftMetricsComponent],
})
export class FloatingShiftMetricsModule { }
