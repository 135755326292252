<div>
    <button *ngIf="shiftButtonLabel" mat-flat-button class="mm-mr-2 mm-bg-green mm-text-white"
        [ngClass]="getShiftButtonClasses()" (click)="changeShiftStatus()" [disabled]="disableShiftActions">
        {{ shiftButtonLabel }} Shift
    </button>

    <button *ngIf="isCompleted" mat-flat-button class="mm-text-white mm-bg-red" (click)="onCompleteShift()"
        style="margin-left:16px" [disabled]="isShiftActionDisabled">
        Complete Shift
    </button>
</div>
<app-floating-shift-metrics [shouldShowFloatingButton]="shouldShowShiftMetrics"></app-floating-shift-metrics>