<!-- Floating Action Button -->
<button *ngIf="shouldShowFloatingButton" class="fab-button mm-text-white" mat-fab color="accent" disableRipple
    aria-label="Show Shift Metrics" (click)="toggleMetrics()" [disabled]="isLoading">
    <mat-icon *ngIf="!isLoading">bar_chart</mat-icon>
    <mat-progress-spinner color="accent" *ngIf="isLoading" diameter="24" mode="indeterminate"></mat-progress-spinner>
</button>

<!-- Floating Shift Metrics Card -->
<div class="floating-metrics" [class.visible]="showMetrics">
    <mat-card class="metrics-card">
        <mat-card-title class="metrics-title">
            <mat-icon class="pulse-icon">trending_up</mat-icon> Shift Metrics
        </mat-card-title>

        <table class="metrics-table">
            <tr *ngFor="let item of metricsMapping">
                <td class="metric-label">{{ item.key }}</td>
                <td class="metric-value">{{ item.value }}</td>
            </tr>
        </table>

        <button mat-icon-button class="close-btn" (click)="toggleMetrics()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-card>
</div>