import { Component, EventEmitter, Output , Inject, Input  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export interface DialogData {
  title: string;
  primaryOptions: string[];
  secondaryOptions?: string[];
  enableTextArea?: boolean;
  textAreaPlaceholder?: string;
  firstPlaceholder?: string;
}

export interface configDetails{
  label?: string,
  placeholder?:string,
  appearance?: string,
  addTagText?: string,
  addTag?: boolean,
  selectable?: boolean,
  removable?: boolean
}

@Component({
  selector: 'app-dialog-out-of-scope',
  templateUrl: './dialog-out-of-scope.component.html',
  styleUrls: ['./dialog-out-of-scope.component.scss']
})


export class DialogOutOfScopeComponent {

  selectedFirstValue: string | null = null;
  selectedSecondValue: string | null = null;
  selectedThirdValue: string | null = null;
  showSecondOptions = false;
  showThirdOptions = false;
  showTextArea = false;
  otherText = '';
  isMultiSelect = false;
 
  firstOptions: string[] = [];
  secondOptions: string[] = [];
  thirdOptions: string[] = [];
  currentSubTitle: string | null = null;
  enableTextArea = false;
  textAreaPlaceholder = '';
  firstPlaceholder = ''
  allAvailableTags: { id: number; name: string }[] = [];
  config:configDetails;

  @Output() dataSelected = new EventEmitter<string>();
  
  constructor(
    public dialogRef: MatDialogRef<DialogOutOfScopeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
    title: string; options: any[];
    firstPlaceholder: string;
    isMultiSelect:boolean;
    config: configDetails;
    tags?: { id: number; name: string}[] 
    }) 
    {
    this.initializeOptions();
    // Extract firstOptions dynamically
    this.firstOptions = this.data.options.reduce((acc, option) => acc.concat(option.firstOption), []);
    //set placeholder value 
    this.firstPlaceholder = this.data.firstPlaceholder || 'Select an option'
  }

  private initializeOptions() {
    this.config = this.data.config
    this.isMultiSelect = this.data.isMultiSelect;

    if (this.isMultiSelect && this.data.tags?.length) {
      // Use tags for multi-select
      this.allAvailableTags = this.data.tags;
    }
  }


  onFirstSelection(selectedValue: string) {
    this.selectedFirstValue = selectedValue;
    this.selectedSecondValue = null;
    this.selectedThirdValue = null;
    this.showSecondOptions = false;
    this.showThirdOptions = false;
    this.showTextArea = false;
    this.otherText = '';

    const selectedOption = this.data.options.find(option => option.firstOption.includes(selectedValue));
    if (selectedOption) {
      this.currentSubTitle = selectedOption.subTitle || null;
      this.enableTextArea = selectedOption.enableTextArea || false;
      this.textAreaPlaceholder = selectedOption.textAreaPlaceholder || 'Please specify the reason';

      if (selectedOption.secondOption?.length > 0) {
        this.secondOptions = selectedOption.secondOption;
        this.showSecondOptions = true;
      } else if (this.enableTextArea === true) {
        // Show text area immediately if enableTextArea is true
        this.showTextArea = true;
      }
    }
  }

  onSecondSelection(selectedValue: string) {
    this.selectedSecondValue = selectedValue;
    this.selectedThirdValue = null;
    this.showThirdOptions = false;
    this.showTextArea = false;

    const selectedOption = this.data.options.find(option =>
      option.secondOption?.includes(selectedValue)
    );

    if (selectedOption?.thirdOption?.length > 0) {
      this.thirdOptions = selectedOption.thirdOption;
      this.showThirdOptions = true;
    }

    // Show text area if the selected second option matches enableTextArea values
    if (Array.isArray(this.enableTextArea) && this.enableTextArea.includes(selectedValue)) {
      this.showTextArea = true;
    }
  }

  onThirdSelection(selectedValue: string) {
    this.selectedThirdValue = selectedValue;
  }

  closeDialog() {
    const returnValue: any = {
      reason: this.selectedFirstValue || null,
      sub_reason: this.showSecondOptions ? this.selectedSecondValue || null : null,
      reason_text: this.showTextArea ? this.otherText.trim() || null : null,
      is_consulted: this.selectedThirdValue ? this.selectedThirdValue.toLowerCase() === 'yes' : null

    };
  
    this.dataSelected.emit(returnValue);
    this.dialogRef.close(returnValue);
  }
  

  canSubmit(): boolean {
    if (this.isMultiSelect) {
      return !!this.selectedFirstValue && this.selectedFirstValue.trim() !== '';
    }

    if (this.showTextArea) {
      return this.otherText.trim() !== '';
    }
    if (this.showThirdOptions) {
      return !!this.selectedThirdValue;
    }
    if (this.showSecondOptions) {
      return !!this.selectedSecondValue;
    }
    return !!this.selectedFirstValue;
  }


  onSelectedTagsChanged(event: { name: string; id: number }[]) {
    if (Array.isArray(event)) {
      this.selectedFirstValue = event.map(e => e.name).join(',');
      this.dataSelected.emit(this.selectedFirstValue);
    }
  }

}
  


