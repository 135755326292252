<mat-menu class="mm-menu" #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">
    <!-- Handle branch node menu items -->
    <span *ngIf="child.children && child.children.length > 0">
      <button mat-menu-item [matMenuTriggerFor]="menu.childMenu">
        <mat-icon class="mm-menu-icon" inline *ngIf="child.iconName">{{child.iconName}}</mat-icon>
        <span>{{child.displayName}}</span>
      </button>
      <app-menu-item (menuClosed)="getClose($event)" #menu [items]="child.children"></app-menu-item>
    </span>
    <!-- Handle leaf node menu items -->
    <span *ngIf="!child.children || child.children.length === 0">
      <button mat-menu-item [class]="{selected: child.selected}" class="mm-menu-item" (click)="handleMenuClose($event, child)">
        <div class="mm-menu-item-hold" *ngIf="child.type === 'default'">
          <mat-icon inline class="mm-menu-icon" *ngIf="child.iconName">{{child.iconName}}</mat-icon>
          <div class="mm-menu-item-name">{{ child.displayName }}</div>
        </div>
        <div class="mm-menu-item-hold" *ngIf="child.type === 'radio'">
          <mat-icon inline class="mm-menu-icon radio-checked mm-text-accent">radio_button_checked</mat-icon>
          <mat-icon inline class="mm-menu-icon radio-unchecked mm-text-accent">radio_button_unchecked</mat-icon>
          <div class="mm-menu-item-name">{{ child.displayName }}</div>
        </div>
        <div class="mm-menu-item-hold" *ngIf="child.type === 'checkbox'">
          <mat-icon inline class="mm-menu-icon radio-checked mm-text-accent">check_box</mat-icon>
          <mat-icon inline class="mm-menu-icon radio-unchecked mm-text-accent">check_box_outline_blank</mat-icon>
          <div class="mm-menu-item-name">{{ child.displayName }}</div>
        </div>
      </button>
    </span>
  </span>
</mat-menu>
