import moment, { Moment } from 'moment-timezone';
import { ProfileType } from '../enums/profile-type.enum';

const profileTimeZoneMap: Partial<Record<ProfileType, string>> = {
    [ProfileType.Doctor]: 'local',
};

const defaultTimeZone = 'Australia/Melbourne';

export const prepareTime = (time: string | Date, profileType: ProfileType): Moment => {
    const timeZone = profileTimeZoneMap[profileType] === 'local'
        ? moment.tz.guess(true)
        : profileTimeZoneMap[profileType] || defaultTimeZone;
    return moment.tz(time, 'UTC').tz(timeZone);
}