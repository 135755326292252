import { Pipe, PipeTransform } from '@angular/core';
import { convertToLocalTime } from '../helpers/utils';

@Pipe({
    name: 'convertToLocalTime'
})
export class ConvertToLocalTimePipe implements PipeTransform {
    /**
     * Transforms UTC time into local time using the utility function.
     * @param utcTime - The UTC timestamp string.
     * @param format - Optional format (default: "YYYY-MM-DD HH:mm:ss").
     * @returns A formatted local time string or an empty string if input is invalid.
     */
    transform(utcTime: string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
        const localTime = convertToLocalTime(utcTime);

        return localTime ? localTime.format(format) : '';
    }
}
