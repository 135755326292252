import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PortalSettingsService } from '../services/portal-settings.service';
import { JwtAuthService } from '../services/auth/jwt-auth.service';

@Injectable()
export class RequestParamsInterceptor implements HttpInterceptor {

    constructor(
        private portalSettingsService: PortalSettingsService,
        private jwtAuthService: JwtAuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has('Skip-Source')) {
            return next.handle(req.clone({ headers: req.headers.delete('Skip-Source') }));
        }

        const additionalParams = this.getAdditionalParams();

        let modifiedReq = req;

        if (['POST', 'PUT', 'PATCH'].includes(req.method)) {
            modifiedReq = req.clone({
                body: req.body instanceof FormData
                    ? this.appendParamsToFormData(req.body, additionalParams)
                    : { ...req.body, ...additionalParams }
            });

        } else if (['GET', 'DELETE'].includes(req.method)) {
            modifiedReq = req.clone({ setParams: additionalParams });
        }

        return next.handle(modifiedReq);
    }

    private getAdditionalParams(): Record<string, string> {
        const source = this.portalSettingsService.settings.source;
        const isDemoUser = this.jwtAuthService.getIsDemoUserView();
        // const userId = this.jwtAuthService.getUser()?.id || '';
        const clinicianId = this.jwtAuthService.getUser()?.clinicianId || '';

        const params: Record<string, string> = {};
        if (source) { params['Source'] = source; }
        // if (isDemoUser && userId) { params['user_id'] = userId; }
        if (clinicianId && isDemoUser) { params['clinician_id'] = clinicianId; }

        return params;
    }

    private appendParamsToFormData(formData: FormData, params: Record<string, string>): FormData {
        const modifiedFormData = new FormData();
        formData.forEach((value, key) => modifiedFormData.append(key, value));
        Object.entries(params).forEach(([key, value]) => modifiedFormData.append(key, value));
        return modifiedFormData;
    }
}
