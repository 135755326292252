import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

export type MenuItemType = 'radio' | 'default' | 'checkbox'
export interface MenuItem {
  id?: number;
  displayName: string;
  iconName?: string;
  children?: MenuItem[];
  action?: string;
  data?: any;
  selected?: boolean;
  type: MenuItemType
}

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  @Input() type: MenuItemType = 'default';
  @Input() items: MenuItem[];
  @Output() menuClosed = new EventEmitter<MenuItem>()
  @ViewChild('childMenu', {static: true}) public childMenu: any;

  constructor() {}
  
  ngOnInit(): void {}

  handleMenuClose(e: Event, item: MenuItem) {
    if (item.type === 'checkbox') {
      e.preventDefault()
      e.stopPropagation()
    }
    this.menuClosed.emit(item)
  }

  getClose(item: MenuItem) {
    this.menuClosed.emit(item)
  }

}
